<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <Filters @filters="setFilters" />
      <TotalForumAllCount :filters="filters"></TotalForumAllCount>
      <HourTrend :filters="filters"></HourTrend>
      <ContentSummary :filters="filters"></ContentSummary>
      <Viewers :filters="filters"></Viewers>
      <DoctorMobileSearch :filters="filters"></DoctorMobileSearch>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../layouts/main.vue"
  import PageHeader from "@/components/page-header"
  import Filters from "./filters.vue"
  import TotalForumAllCount from "./TotalForumAllCount.vue"
  import HourTrend from "./HourTrend.vue"
  import ContentSummary from "./ContentSummary.vue"
  import Viewers from "./Viewers.vue"
  import DoctorMobileSearch from "./DoctorMobileSearch.vue"
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      PageHeader,
      Filters,
      TotalForumAllCount,
      HourTrend,
      ContentSummary,
      Viewers,
      DoctorMobileSearch
  },
    data() {
      return {
        buttons: [
          { caption: "MCQ's", state: true, id: "btn1" },
          { caption: "Posts", state: false, id: "btn2" },
        ],
        title: "Community Management",
        items: [
          {
            text: "Medisage",
          },
          {
            text: "Forum",
            active: true,
          },
        ],
        toggle: "btn1",
        summary: {},
        previousSummary: {},
        state_summary: {},
        filters: {},
        reload: 0,
        params: {},
        loading: true,
      };
    },
    created() {
      // this.init();
    },
    methods: {
      btnClick(click_id) {
        this.buttons.map((btn) => {
          if (btn.id == click_id) {
            btn.state = true;
            this.toggle = btn.id;
          } else {
            btn.state = false;
          }
        });
      },
      init() {
        // this.loading = true;
        // this.loading = false;
      },
      setFilters(v) {
        this.filters = v;
      },
    },
    watch: {
      filters: {
        deep: true,
        handler() {
          // this.init();
        },
      },
    },
  };
  </script>
  