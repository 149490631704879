<template>
  <div class="card">
    <div class="card-body">
      <!-- <div class="d-flex justify-content-between align-items-center"> -->
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title m-0">Content Summary</h4>
        <JsonExcel
          class="btn p-0"
          :data="items"
          name="content.csv"
          type="csv"
        >
          <i class="mdi mdi-download"></i>
          Download
        </JsonExcel>
      </div>
      <div class="table-responsive">
        <b-table
          :items="items"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered">
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_pa ginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import appConfig from "@/app.config";
import filterMixins from "../../../mixins/filterData";
import JsonExcel from "vue-json-excel";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    JsonExcel,
  },
  data() {
    return {
      content: [],
      overallCount: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "cities",
      sortDesc: false,
      fields: [
        { key: "content", label: "Content" },
        { key: "total_views", label: "Views" },
        { key: "total_viewers", label: "Viewers" },
      ],
      items: [
        { content: "Article", total_views: 0, total_viewers: 0 },
        { content: "Cases", total_views: 0, total_viewers: 0 },
        { content: "Newsletter", total_views: 0, total_viewers: 0 },
        { content: "Video", total_views: 0, total_viewers: 0 }
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getCityList();
      },
    },
    // overallCount() {
    //   this.getCityList();
    // }
  },
  computed: {
    /* Total no. of records */
    rows() {
      return this.content.length;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getCityList() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/forum/content-summary",
          {
            params: searchfiltersParams,
          }
        );
        this.content = response.data;

        this.items.map((item) => {
          if (item.content == "Article") {
            item.total_views = this.content.article_total_views;
            item.total_viewers = this.content.article_total_viewers;
          } else if (item.content == "Cases") {
            item.total_views = this.content.cases_total_views;
            item.total_viewers = this.content.cases_total_viewers;
          } else if (item.content == "Newsletter") {
            item.total_views = this.content.newsletter_total_views;
            item.total_viewers = this.content.newsletter_total_viewers;
          } else if (item.content == "Video") {
            item.total_views = this.content.video_total_views;
            item.total_viewers = this.content.video_total_viewers;
          }
        });
        //this.items=this.content;
        // this.article_total_views = this.content.article_total_views;
        // this.items.map()
        // console.log(this.article_total_views);
        // console.log(this.content);
        // this.totalRows = this.content.length;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getCityList();
  },
};
</script>
  